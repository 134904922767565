import './index.css';

export type HelloWorldProps = {
  onHelloWorldAnimEnd: () => void;
};

export const HelloWorld = (props: HelloWorldProps) => {
  const onAnimationEnd = () => {
    props.onHelloWorldAnimEnd();
  };

  return (
    <div className="hw-container">
      <div className={`hello-world`} onAnimationEnd={onAnimationEnd}>
        <h1>Hello World!</h1>
      </div>
    </div>
  );
};
