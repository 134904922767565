import { useState } from 'react';
import { HelloWorld } from './HelloWorld';
import { Page } from './Page';

export const Main = () => {
  const [helloWorld, setHelloWorld] = useState(true);

  const onHelloWorldAnimEnd = () => {
    setHelloWorld(false);
  };

  return (
    <>
      {helloWorld ? (
        <HelloWorld onHelloWorldAnimEnd={() => onHelloWorldAnimEnd()} />
      ) : (
        <Page />
      )}
    </>
  );
};
