import { useEffect, useState } from 'react';
import './index.css';

export const WaveAnim = () => {
  const [classNames, setClassNames] = useState(Array(50).fill(''));

  useEffect(() => {
    const timeoutArray: NodeJS.Timeout[] = [];
    for (let i = 0; i < classNames.length; i++) {
      timeoutArray.push(
        setTimeout(() => (classNames[i] = 'wavemove'), (i + 1) * 200)
      );
      timeoutArray.push(
        setTimeout(() => setClassNames([...classNames]), (i + 1) * 200)
      );
    }

    return () => {
      for (let i = 0; i < timeoutArray.length; i++) {
        clearTimeout(timeoutArray[i]);
      }
    };
  }, []);

  return (
    <div className="wave-container">
      {classNames.map((item, index) => {
        return (
          <div key={index} className={`wave-item ${classNames[index]}`}>
            <b>.</b>
          </div>
        );
      })}
    </div>
  );
};
