import { PageProps } from '../types';
import './index.css';
import p1 from '../../../../Images/p1.jpg';
import p2 from '../../../../Images/p2.jpg';
import p3 from '../../../../Images/p3.jpg';
import p4 from '../../../../Images/p4.jpg';
import p5 from '../../../../Images/p5.jpeg';
import p6 from '../../../../Images/p6.jpg';
import p7 from '../../../../Images/p7.jpg';
import p8 from '../../../../Images/p8.png';
import p9 from '../../../../Images/p9.jpg';

export const ProjectsBody = (props: PageProps) => {
  const projectsArray = [
    {
      name: 'Crews',
      description:
        'A reddit-like social media website with enhanced messaging.',
      picture: p1,
      githubLink: '',
      releaseLink: ''
    },
    {
      name: 'CryptoWatch',
      description: 'A cryptocurrency mock portfolio appilcation.',
      picture: p2,
      githubLink: '',
      releaseLink: ''
    },
    {
      name: 'ODAW',
      description:
        'An online music creation platform. (Online Digital Audio Workspace)',
      picture: p3,
      githubLink: '',
      releaseLink: ''
    },
    {
      name: 'TCP-IP Centralized Server',
      description: 'A TCP-IP Client and Server written in Python.',
      picture: p7,
      githubLink:
        'https://github.com/George-Freedland/TCP-IP-Centralized-Server',
      releaseLink: ''
    },
    {
      name: 'Google Maps App',
      description: 'A simple TypeScript app using the google maps API.',
      picture: p8,
      githubLink:
        'https://github.com/George-Freedland/typescript-googlemaps-app',
      releaseLink: ''
    },
    {
      name: 'Matrix Calculator',
      description:
        'Converts matricies to Row Echelon Form, console Java program.',
      picture: p4,
      githubLink: 'https://github.com/George-Freedland/MatrixCalculator',
      releaseLink: ''
    },
    {
      name: 'Snake',
      description: 'A simple snake game written in Python.',
      picture: p5,
      githubLink: 'https://github.com/George-Freedland/Snake',
      releaseLink: ''
    },
    {
      name: 'Flappy Bird Clone',
      description: 'A simple flappy bird clone written in Python.',
      picture: p6,
      githubLink: 'https://github.com/George-Freedland/flappy-bird',
      releaseLink: ''
    },
    {
      name: 'Personal Website',
      description: 'This website!',
      picture: p9,
      githubLink: 'https://github.com/George-Freedland/personal-website',
      releaseLink: 'https://www.george-freedland.com/'
    }
  ];

  type ProjectProps = {
    key: number;
    name: string;
    description: string;
    picture: string;
    githubLink?: string;
    releaseLink?: string;
    onClick: (item: ProjectProps) => void;
  };

  const Project = (props: ProjectProps) => {
    return (
      <div className="project" onClick={() => props.onClick(props)}>
        <div className="inner-project">
          <div className="project-pic-container">
            <img
              className="project-pic"
              src={props.picture}
              alt={props.name}
            />
          </div>
          <div className="project-name">{props.name}</div>
          <div className="project-desc">
            {props.description}
            {props.githubLink ? (
              <div onClick={() => window.open(props.githubLink, '_blank')}>
                <br />
                <b>Github Link</b>
              </div>
            ) : null}
            {props.releaseLink ? (
              <div onClick={() => window.open(props.releaseLink, '_blank')}>
                <br />
                <b>Release Link</b>
              </div>
            ) : null}
            {!(props.githubLink || props.releaseLink) ? (
              <div>
                <br />
                <b>No links currently available.</b>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const handleClick = (item: ProjectProps) => {
    if (item.releaseLink !== '') window.open(item.releaseLink, '_blank');
    else if (item.githubLink !== '') window.open(item.githubLink, '_blank');
  };

  return (
    <div
      className={`projects-body ` + props.classNames}
      onAnimationEnd={() => props.onAnimEnd()}
    >
      {projectsArray.map((item, index) => {
        return (
          <Project
            onClick={(item: ProjectProps) => handleClick(item)}
            key={index}
            name={item.name}
            description={item.description}
            picture={item.picture}
            githubLink={item.githubLink}
            releaseLink={item.releaseLink}
          />
        );
      })}
    </div>
  );
};
