import { Routes, Route } from 'react-router-dom';
import { Error } from '../Pages/Error';
import { Main } from '../Pages/Main';
import { AISolutions } from '../Pages/AISolutions';
import './index.css';

export const App = () => {
  return (
    <div className="App-header">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/aisolutions" element={<AISolutions />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};
