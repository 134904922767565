import { useState } from 'react';
import './index.css';

export type HeaderProps = {
  classNames: string;
  canSwitch: boolean;
  onSwitch: (id: string) => void;
};

export const Header = (props: HeaderProps) => {
  const [classNames, setClassNames] = useState('');
  const [currentPage, setCurrentPage] = useState('1');

  const handleNavClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (props.canSwitch && currentPage !== event.currentTarget.id) {
      setClassNames(`underscore-${event.currentTarget.id}`);
      setCurrentPage(event.currentTarget.id);
      props.onSwitch(event.currentTarget.id);
    }
  };

  return (
    <div className={`header ${props.classNames}`}>
      <button
        id="1"
        onClick={(event) => handleNavClick(event)}
        className="nav-item"
      >
        Home
      </button>
      |
      <button
        id="2"
        onClick={(event) => handleNavClick(event)}
        className="nav-item"
      >
        Projects
      </button>
      <div className={`nav-underscore ${classNames}`} />
    </div>
  );
};
