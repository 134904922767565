import './index.css';
import linkedinLogo from '../../../../Images/linkedinLogo.png';
import githubLogo from '../../../../Images/githubLogo.png';
import soundcloudLogo from '../../../../Images/soundcloudLogo.png';
import { PageProps } from '../types';

export const Footer = (props: PageProps) => {
  const handleLinkedin = () => {
    window.open(
      'https://www.linkedin.com/in/george-freedland-9a874274',
      '_blank'
    );
  };

  const handleGithub = () => {
    window.open('https://github.com/George-Freedland', '_blank');
  };

  const handleSoundcloud = () => {
    window.open('https://soundcloud.com/96clouds', '_blank');
  };

  return (
    <div
      className={`footer ${props.classNames}`}
      onAnimationEnd={() => props.onAnimEnd()}
    >
      <button className="social-icon-1">
        <img
          onClick={() => handleLinkedin()}
          className="icon-pic"
          src={linkedinLogo}
          alt="LinkedIn Link"
        />
      </button>

      <button className="social-icon-2">
        <img
          onClick={() => handleGithub()}
          className="icon-pic"
          src={githubLogo}
          alt="Github Link"
        />
      </button>

      <button className="social-icon-3">
        <img
          onClick={() => handleSoundcloud()}
          className="icon-pic"
          src={soundcloudLogo}
          alt="Soundcloud Link"
        />
      </button>
    </div>
  );
};
