import { Link } from 'react-router-dom';
import './index.css';

export const Error = () => {
  return (
    <>
      <div className="page-not-found-content">
        <h2>Whoops this page doesn't exist!</h2>
        <Link className="link" to="/">
          Let's go back to the Home Page
        </Link>
      </div>
    </>
  );
};
