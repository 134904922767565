
export const AISolutions = () => {


  return (
    <>
      <div>AI Solutions</div>
    </>
  );
};
