// import { useState } from "react";
import './index.css';
import pic from '../../../../Images/profilepic.jpg';
import { WaveAnim } from './WaveAnim';
import { PageProps } from '../types';

const desc = (
  <>
    <div className="body-intro">
      <img className="avatar-pic" src={pic} alt="Avatar" />
      <p className="intro-text">
        Welcome to my website! <br /> My name is George and I am a Full-Stack
        Software Engineer.
      </p>
    </div>
    <div className="body-rest">
      <WaveAnim />
      <div className="about-me">
        <b>
          <u>About Me</u>
        </b>
      </div>
      <div className="body-desc">
        <p>
          I love exploring and working with new technologies such as Blockchain,
          Machine Learning, AR/VR, etc..., building prototypes, and finding
          solutions to complex problems.
        </p>
        <p>
          In my spare time I enjoy writing electronic/lo-fi music, making
          mini-games, and working on many other projects such as this website!{' '}
          <br />
          (Made with React and TypeScript).
        </p>
        <p className="body-end">
          Click on the <b>'Projects'</b> tab above to see more!
        </p>
      </div>
    </div>
  </>
);

export const HomeBody = (props: PageProps) => {
  return (
    <div
      className={`home-body ` + props.classNames}
      onAnimationEnd={() => props.onAnimEnd()}
    >
      <div className="desc-container">{desc}</div>
    </div>
  );
};
