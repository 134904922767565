import { useEffect, useState } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
import { HomeBody } from './HomeBody';
import { ProjectsBody } from './ProjectsBody';

export const Page = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [showArray, setShowArray] = useState([true, false]);
  const [currentPage, setCurrentPage] = useState('1');
  const [headerClassName, setHeaderClassName] = useState('');
  const [homeClassName, setHomeClassName] = useState('');
  const [projectsClassName, setProjectsClassName] = useState('');
  const [footerClassName, setFooterClassName] = useState('');

  useEffect(() => {
    setTimeout(() => setHeaderClassName('fade-in'), 500);
    setTimeout(() => setHomeClassName('fade-in'), 1000);
    setTimeout(() => setFooterClassName('fade-in'), 1500);
  }, []);

  const onFooterAnimEnd = () => {
    setPageLoading(false);
  };

  const onProjectsAnimEnd = () => {
    if (currentPage === '2') {
      setPageLoading(false);
    }
    if (currentPage === '1') {
      setShowArray([true, false]);
      setHomeClassName('fade-in');
    }
  };

  const onHomeAnimEnd = () => {
    if (currentPage === '1') {
      setPageLoading(false);
    }
    if (currentPage === '2') {
      setShowArray([false, true]);
      setProjectsClassName('fade-in');
    }
  };

  const onSwitch = (id: string) => {
    setPageLoading(true);
    if (id === '1') {
      setCurrentPage(id);
      setProjectsClassName('fade-out');
    } else if (id === '2') {
      setCurrentPage(id);
      setHomeClassName('fade-out');
    }
  };

  return (
    <div>
      <Header
        classNames={headerClassName}
        canSwitch={!pageLoading}
        onSwitch={(id: string) => onSwitch(id)}
      />
      {showArray[0] === true && (
        <HomeBody classNames={homeClassName} onAnimEnd={onHomeAnimEnd} />
      )}
      {showArray[1] === true && (
        <ProjectsBody
          classNames={projectsClassName}
          onAnimEnd={onProjectsAnimEnd}
        />
      )}
      <Footer classNames={footerClassName} onAnimEnd={onFooterAnimEnd} />
    </div>
  );
};
